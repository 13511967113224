/* LUCKIEST FONTS */
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Space Grotesk', sans-serif;
}

:root {
    --light_gray: rgb(239, 239, 239);
    --text_color: black;
    --background_color: white;
}

/* CBF IOS */
input {
    font-size: 16px;
}

html, body { height:100%; font-size: 16px; background-color: var(--background_color); }

.standard_text {
    color: var(--text_color);
}

.standard_curve {
    border-radius: 5px;
    overflow: hidden;
}

.border_bottom {
    border-bottom: 1px solid #ccc;
}

.border_top {
    border-top: 1px solid #ccc;
}

.hidden {
    display: none;
}

.tiny_font {
    font-size: 12px;
}

.top_bar {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    color: var(--text_color);
    background-color:  var(--light_gray);
}

.top_bar:hover {

}

.mint_now_button {
    display: block;
    width: 150px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: white;
    font-size: 20px;
}

.mint_now_button:hover {
    width: 148px;
    height: 48px;
}


/* LUCKY DOODS BE CHUNCKY */

.chuncky_pink {
    background-color: #9963ea;
    border: 1px solid #8226b1;
    border-bottom: 3px solid #8226b1;
    border-right: 3px solid #8226b1;
    cursor: pointer;
}

.chuncky_pink:hover {
    border-bottom: 5px solid #8226b1;
    border-right: 5px solid #8226b1;
}

.chuncky_blue {
    background-color: #48b9ff;
    border: 1px solid #006fb3;
    border-bottom: 3px solid #006fb3;
    border-right: 3px solid #006fb3;
	vertical-align: bottom;
	cursor: pointer;
}

.chuncky_blue:hover {
    border-bottom: 5px solid #006fb3;
    border-right: 5px solid #006fb3;
}


.chuncky_gray {
    background-color:  var(--light_gray);
    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    border-right: 3px solid #ccc;
	cursor: pointer;
}

.chuncky_gray:hover {
	border-bottom: 5px solid #ccc;
    border-right: 5px solid #ccc;
}

.lighting_button {
    position: relative;
    margin-top: 20px;
    display: inline-block;
    float: right;
    width: 50px;
    height: 50px;
    margin-left: 10px;
    background-color: #353535;
}

.lighting_button:hover {
    margin-top: 18px;
    width: 48px;
}

.tiny_font {
    font-size: 12px;
    cursor: pointer;
}