

.component {
    margin-left: 20px;
    width: calc(100% - 40px);
}

.component_magin_top {
    margin-top: 20px;
}

.component_magin_bottom {
    margin-bottom: 20px;
}

.component_magin_top_large {
    padding-top: 20px;
}

.component_magin_top_xlarge {
    padding-top: 40px;
}


.component_magin_bottom_large {
    margin-bottom: 40px;
}

.full_image {
    width: 100%;
}

.road_map_image {
    margin-left: 100px;
    width: 800px;
}

.two_thirds_container {
    display: inline-block;
    width: 65%;
    vertical-align: bottom;
}

.inner_text {
    padding-bottom: 20px;
    padding-top: 20px;
}

.inner_text_left {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 40px;
}

.inner_text_right {
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 40px;
}

.one_third_container {
    display: inline-block;
    width: 35%;
    vertical-align: bottom;
    margin-bottom: 20px;
}

.half_container {
    display: inline-block;
    width: 50%;
    vertical-align: bottom;
    margin-bottom: 20px;
}

.bottom_floated_container {
    position: relative;
    width: 100%;
    vertical-align: bottom;
    min-height: 20px;

    vertical-align: bottom;
}

.iframe_container {
    position: absolute;
    bottom: -100px;
    right: -100px;
    width: 400px;
    height: 400px;
    overflow: hidden;
    transform: scale(2);
}

.iframe_ordinals {
    position: absolute;
    top: -69px;
    left: -100px;
    width: 600px;
    height: 600px;
}

span {
    font-family: 'Courier New', monospace;
}

.layer_container {
    position: absolute;
    bottom: -100px;
    right: -100px;
    overflow: hidden;
    width: 300px;
    height: 300px;
    background-color: #c8dbe9;
    transform: scale(1);
    pointer-events: none;
}

.image_layer {
    position: absolute;
    top: -49px;
    left: -76px;
    width: 400px;
    height: 400px;
    font: 9px / 11px monospace;
    transform: scale(0.76);
}


.stick_to_bottom {
    position: absolute;
    bottom: -10px;
}

@media only screen and (min-width: 1000px) {
    .component {
        margin-left: calc(50% - 500px);
        width: 1000px;
    }

    
}

.mobile_only {
    display: none;
}

@media only screen and (max-width: 900px) {

    .road_map_image {
        margin-left: 0px;
        width: 100%;
    }

    .half_container {
        width: 100%;
    }

    .half_container_modifier {
        margin-left: -15px;
        width: calc(100% + 30px);
    }

    .component {
        margin-left: calc(50% - 250px);
        width: 500px;
    }

    .two_thirds_container {
        margin-left: calc(50% - 250px);
        width: 500px;
    }

    .one_third_container {
        margin-left: calc(50% - 250px);
        width: 500px;
    }
    
    .desktop_only {
        display: none;
    }

    .mobile_only {
        display: block;
    }

    .inner_text_left {
        /* padding-bottom: 10px; */
        /* padding-top: 10px; */
        padding-right: 0px;
        
    }
    
    .inner_text_right {
        /* padding-bottom: 10px; */
        /* padding-top: 10px; */
        padding-left: 0px;
    }
}

@media only screen and (max-width: 520px) {
    .two_thirds_container {
        margin-left: 0px;
        width: 100%;
    }

    .one_third_container {
        margin-left: 0px;
        width: 100%;
    }

    .component {
        margin-left: 20px;
        width: calc(100% - 40px);
    }
}