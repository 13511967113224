/* TEMP */
.minting_container {
    position: relative;
    margin-left: 0px;
    margin-top: 20px;
    /* width: 500px;
    height: 479px; */
    /* width: 320px;
    height: 307px; */
    width: 100%;
    max-width: 500px;
    /* background-color: red; */
}




.minting_machine {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}

.animation_layer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.sticker {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    
}

.clickable {
    cursor: pointer;
}

.minting_ui_container {
    position: absolute;
    top: calc(50% - 105px);
    left: 25%;
    width: 55%;
    height: 220px;
    /* background-color: red; */
}

.main_title {
    position: absolute;
    top: 0px;
    width: 100%;
    text-align: center;
}

.loading_title {
    position: absolute;
    top: calc(50% - 30px);
    width: 100%;
    text-align: center;
}

.upper_button {
    position: absolute;
    top: 60px;
    width: calc(100% - 4px);
    height: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    color: white;
    background-color: #48b9ff;
    border: 1px solid #006fb3;
    border-bottom: 3px solid #006fb3;
    border-right: 3px solid #006fb3;
    border-radius: 5px;

	cursor: pointer;
}

.upper_button:hover {
    top: 58px;
    width: calc(100% - 6px);
    border-bottom: 5px solid #006fb3;
    border-right: 5px solid #006fb3;
}

.cross_mint_button {
    position: absolute;
    top: 120px;
    width: calc(100% - 4px);
    height: 50px;
}

.main_button {
    position: absolute;
    top: 120px;
    width: calc(100% - 4px);
    height: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    color: white;
    background-color: #48b9ff;
    border: 1px solid #006fb3;
    border-bottom: 3px solid #006fb3;
    border-right: 3px solid #006fb3;
    border-radius: 5px;

	cursor: pointer;
}

.main_button:hover {
    top: 118px;
    width: calc(100% - 6px);
    border-bottom: 5px solid #006fb3;
    border-right: 5px solid #006fb3;
}

.minting_input {
    position: absolute;
    top: 50px;
    width: calc(100% - 4px);
    height: 50px;
    
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    background-color: white;
    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    border-right: 3px solid #ccc;
    border-radius: 5px;
}

.minting_input:hover {
    top: 48px;
    width: calc(100% - 6px);
    border-bottom: 5px solid #ccc;
    border-right: 5px solid #ccc;
}

.decrement_button {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 50px;
    height: 50px;
    
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    background-color: white;
    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    border-right: 3px solid #ccc;
    border-radius: 5px;
    font-size: 30px;

    cursor: pointer;
}

.decrement_button:hover {
    top: 48px;
    width: 48px;
    border-bottom: 5px solid #ccc;
    border-right: 5px solid #ccc;

}

.increment_button {
    position: absolute;
    top: 50px;
    right: 3px;
    width: 50px;
    height: 50px;
    
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    background-color: white;
    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    border-right: 3px solid #ccc;
    border-radius: 5px;
    font-size: 30px;

    cursor: pointer;

    
}

.unselectable_text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.increment_button:hover {
    top: 48px;
    width: 48px;
    border-bottom: 5px solid #ccc;
    border-right: 5px solid #ccc;
}

u {
    cursor: pointer;
}

u:hover {
    color: #006fb3;
}

.minting_qty_input {
    position: absolute;
    top: 50px;
    left: 60px;
    width: calc(100% - 127px);
    height: 50px;
    
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    background-color: white;
    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    border-right: 3px solid #ccc;
    border-radius: 5px;
}

.minting_qty_input:hover {
    top: 48px;
    width: calc(100% - 129px);
    border-bottom: 5px solid #ccc;
    border-right: 5px solid #ccc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.explanation_text {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 4px);
    height: 30px;
    font-size: 12px;
}

.transaction_tracker {
    position: absolute;
    top: 22%;
    left: 25%;
    width: calc(55% - 2px);
    /* height: 260px; */
    height: 62%;
    
    background-color: white;
    border: 1px solid #ccc;
    border-bottom: 3px solid #ccc;
    border-right: 3px solid #ccc;
    border-radius: 5px;


    border-radius: 5px;
    display: none;
    /* background-color: rgba(0, 0, 0, 0.1); */
}




.tracker_inner {
    position: absolute;
    top: 20px;
    width: 100%;
    height: calc(100% - 40px);
}

.purchase_confirmation {
    position: absolute;
    top: 50px;
    left: 20px;
    width: calc(100% - 40px);
    font-size: 14px;
}



@media only screen and (max-width: 390px) {
    .explanation_text {

        top: 71vw;
        left: -20px;
        width: calc(100% + 40px);
    }
    .minting_ui_container {
        top: calc(50% - 80px); 
        height: 150px;
    }
}

/* @media only screen and (max-width: 440px) {
    .minting_ui_container {
        top: 20%;
        left: 22.2%;
        width: 60.8%;
        height: 55.8%;
    }
} */



@media only screen and (max-width: 410px) {
    .purchase_confirmation {
        /* top: 35px; */
        /* font-size: 12px; */
    }

    .transaction_tracker {
        top: 15.1%;
        left: 20.2%;
        width: 62.8%;
        height: 74.8%;
    }
}

@media only screen and (max-width: 330px) {
    .purchase_confirmation {
        top: 35px;
        font-size: 12px;
    }
}

/* @media only screen and (max-width: 360px) {
    .purchase_confirmation {
        top: 35px;
        font-size: 10px;
    }
} */

.mint_more_button {
    position: absolute;
    bottom: 0px;
    left: 20px;
    width: calc(100% - 40px);
    height: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    color: white;
    background-color: #48b9ff;
    border: 1px solid #006fb3;
    border-bottom: 3px solid #006fb3;
    border-right: 3px solid #006fb3;
    border-radius: 5px;

	cursor: pointer;
}

.mint_more_button:hover {
    width: calc(100% - 42px);
    border-bottom: 5px solid #006fb3;
    border-right: 5px solid #006fb3;
}

.smoke_cloud {
    position: absolute;
    top: 19%;
    left: 11%;
    width: 4%;
    /* animation: 1s infinite linear animated_cloud; */
}

@keyframes animated_cloud {
    0% {
        top: 19%;
        left: 11%;
        width: 4%;
        opacity: 0;
    }
    30% {
        top: 16.4%;
        left: 4.1%;
        width: 6.5%;
        opacity: 1;
    }
    60% {
        top: 10%;
        left: 1%;
        width: 4%;
        opacity: 1;
    }
    100% {
        top: 3.8%;
        left: 0%;
        width: 3%;
        opacity: 0;
        transform: rotate(90deg);
    }
}


.gear_0 {
    position: absolute;
    top: 44%;
    left: 1%;
    width: 10%;
    animation: 3s infinite linear animated_gear_0;
}



.gear_1 {
    position: absolute;
    top: 45%;
    left: 8.1%;
    width: 18%;
    animation: 3s infinite linear animated_gear_1;
}

@keyframes animated_gear_0 {
    0% {
        transform: rotate(-180deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(-180deg);
    }
}

@keyframes animated_gear_1 {
    0% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(-10deg);
    }
}

.sattelite_dish {
    position: absolute;
    top: 0.2%;
    right: 7.6%;
    width: 25%;
    animation: 10s infinite linear animated_sattelite_dish;
}

@keyframes animated_sattelite_dish {
    0% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(30deg);
    }
    100% {
        transform: rotate(-15deg);
    }
}

.rainbow_container {
    position: absolute;
    top: 35.4%;
    right: 5.3%;
    height: 41%;
    /* transform: rotate(90deg); */
    /* z-index: 1000; */
    /* background-color: red; */
    object-fit: cover;
    width: 2.8%;
    /* opacity: 0.5; */
    overflow: hidden;
}

.rainbow_inner {
    position: absolute;
    top: -500%;
    width: 100%;
    animation: 5s infinite linear animated_rainbow;
}

@keyframes animated_rainbow {
    0% {
        top: -141%;
    }
    50% {
        top: -1%;
    }
    100% {
        top: -141%;
    }
}