

.cycle_container {
    margin-top: 5px;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
}

.token_number_text {
    display: inline-block;
    width: calc(100% - 140px);
    height: 50px;
    margin-top: 5px;
    line-height: 20px;
    vertical-align: top;
}

.navigation_button {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    float: right;
    margin-left: 10px;
}

.navigation_button:hover {
    margin-top: -2px;
    width: 48px;
}