

.gallery_container {
    width: calc(100% + 20px);
}

.gallery_image {
    display: inline-block;
    margin-bottom: 16px;
    width: calc(25% - 15px);
    margin-right: 20px;
}

.gallery_image:nth-child(4n) {
    margin-right: 0px;
}
/* .gallery_image_margin_right {
    margin-right: 20px;
*/

@media only screen and (max-width: 900px) {

    .gallery_image {
        display: inline-block;
        margin-bottom: 16px;
        width: calc(50% - 20px);
    }

}