

.header_container {
    height: 90px;
    background-color: black;
}

.logo {
    margin-top: 20px;
    height: 50px;
    object-fit: contain;
}

.social_button {
    position: relative;
    margin-top: 20px;
    display: inline-block;
    float: right;
    width: 120px;
    height: 50px;
    margin-left: 10px;
}

.social_button:hover {
    margin-top: 18px;
    width: 118px;
}

.social_button_icon {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    left: 10px;
}

.social_button_text {
    position: absolute;
    width: 60px;
    height: 30px;
    line-height: 30px;
    top: 10px;
    left: 50px;
    color: white;
}

.header_line_component {
    height: 15px;
    display: none;
}

@media only screen and (max-width: 550px) {
    .social_button {
        width: 50px;
    }
    .social_button:hover {
        width: 48px;
    }
    .social_button_text {
        display: none;
    }
}

@media only screen and (max-width: 400px) {
    .logo {
        width: calc(100% - 140px);
    }
}